import { captureException } from '@sentry/react';
import { Spinner } from '@yolaw/ui-kit-components';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ZenSubscriptionModal } from './components/Modals';
import { SEARCH_PARAMS } from './constants';
import useProject from './hooks/useProject';
import useZenApp from './hooks/useZenApp';
import coreApi from './services/apis/coreApi';
import config from './services/config';
import { PATH_PARAMS } from './services/routes';
import { setAccessToken } from './utils/auth';

const LoadingPage = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

type LegalEntity = {
  id: number;
  funnel_id: number;
};

const findProjectLegalEntity = async (project: Project): Promise<number | undefined> => {
  if (project.legalentity_id) return project.legalentity_id;

  const response = await coreApi.get<LegalEntity[]>('/my/legal-entities/');
  return response?.data?.find((le) => le.funnel_id === project.funnel_id)?.id;
};

const redirectToUserspace = async (project: Project, onError: VoidFunction) => {
  try {
    const leID = await findProjectLegalEntity(project);
    if (!leID) throw new Error('Missing LegalEntity ID');

    let userspaceBaseURL = 'https://app.legalstart.fr';

    if (config.NODE_ENV !== 'production') {
      // Find linked Userspace for testenv
      const response = await coreApi.get('/env_var_configuration/');
      const linkedUSBaseURLForTestenv = response?.data?.USERSPACE_BASE_URL;
      if (!linkedUSBaseURLForTestenv) throw new Error('Can not find the linked Userspace env');
      userspaceBaseURL = linkedUSBaseURLForTestenv;
    }

    // Construct the redirection URL
    const userspacePath = `/le/${leID}/zen`;
    const redirectionURL = userspaceBaseURL + userspacePath;

    // Perform the redirection
    window.location.replace(redirectionURL);
  } catch (error) {
    // Log Sentry error
    captureException(new Error('Can not redirect to Userspace'), {
      extra: {
        error,
        project
      }
    });

    // Error callback
    onError();
  }
};

const ProjectInitializer: React.FC = () => {
  const app = useZenApp();
  const project = useProject();

  const urlPathParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isGettingRedirectionURL, setIsGettingRedirectionURL] = useState(true);

  const getUserAndProjectInfo = (projectId: string) => {
    // Try to get user information
    app.user.getUserInfo();
    project.getProjectInfo(projectId);
  };

  useEffect(() => {
    if (searchParams.has(SEARCH_PARAMS.USER_TOKEN)) {
      setAccessToken(searchParams.get(SEARCH_PARAMS.USER_TOKEN));
      searchParams.delete(SEARCH_PARAMS.USER_TOKEN);
    }

    if (searchParams.has(SEARCH_PARAMS.LS_USER_ID)) {
      searchParams.delete(SEARCH_PARAMS.LS_USER_ID);
    }

    if (urlPathParams[PATH_PARAMS.PROJECT_ID]) {
      const projectId = urlPathParams[PATH_PARAMS.PROJECT_ID];
      getUserAndProjectInfo(projectId);
    }

    // Update search params with removal of user related params
    setSearchParams(searchParams);
  }, []);

  const documentClickListener = (event: MouseEvent) => {
    const { target } = event;
    // Click on modal background of an opening modal
    if (
      target instanceof HTMLElement &&
      target.classList.contains('modal-container') &&
      target.classList.contains('opened')
    ) {
      app.modal.closeModal();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('click', documentClickListener);
    return () => {
      document.removeEventListener('click', documentClickListener);
    };
  }, []);

  const onGetRedirectionURLError = () => setIsGettingRedirectionURL(false);

  useEffect(() => {
    if (project.info.id) {
      // As of Nov 2024, we try to redirect all Zen access to Userspace
      redirectToUserspace(project.info, onGetRedirectionURLError);
    }
  }, [project.info.id]);

  if (!project.info || !project.onboardingQuestionnaire || isGettingRedirectionURL)
    return (
      <LoadingPage>
        <Spinner color="secondary.main" />
      </LoadingPage>
    );

  return (
    <>
      <Outlet />
      <ZenSubscriptionModal />
    </>
  );
};

export default ProjectInitializer;
